import { useMemo, useState, useSyncExternalStore, useTransition } from "react";
import { useInterval } from "@business-finland/wif-ui-lib";

import { ONE_SEC_IN_MS } from "../../../constants/durations";

export default function useCurrentCategory(
  categories: Jobs.Status.ICategory[] = [],
  isHovering?: boolean
): Jobs.Status.ICategory | undefined {
  const MAX_INDEX = categories.length;

  const [, startTransition] = useTransition();
  const [index, setIndex] = useState(0);
  const isWindowVisible = useIsWindowVisible();

  const isIntervalDisabled =
    categories.length === 0 || !isWindowVisible || !!isHovering;

  useInterval(
    () =>
      startTransition(() => {
        setIndex((i) => (i + 1) % MAX_INDEX);
      }),
    8 * ONE_SEC_IN_MS,
    { disabled: isIntervalDisabled }
  );

  return useMemo(() => categories[index], [categories, index]);
}

function useIsWindowVisible() {
  return useSyncExternalStore(
    subscribeToVisibilityChange,
    getWindowVisibilitySnapshot,
    () => false
  );
}

function subscribeToVisibilityChange(listener: () => void) {
  window.document.addEventListener("visibilitychange", listener);
  return () =>
    window.document.removeEventListener("visibilitychange", listener);
}

function getWindowVisibilitySnapshot() {
  return window.document.visibilityState === "visible";
}
