import {
  Block,
  Button,
  Link,
  useElementHover,
} from "@business-finland/wif-ui-lib";
import { JobCard } from "@business-finland/wif-ui-lib/dist/JobList";

import { generateOpenJobsPageLink, getStyleClassName } from "./helpers";
import useJobsQuery from "./useJobsQuery";
import useCurrentCategory from "./useCurrentCategory";

export function LatestOpenings({
  title,
  categories = [],
  seeMoreButtonText,
}: {
  title: string;
  categories: Jobs.Status.ICategory[];
  seeMoreButtonText: string;
}): JSX.Element | null {
  const { ref, isHovering } = useElementHover<HTMLDivElement>();
  const currentCategory = useCurrentCategory(categories, isHovering);
  const jobs = useJobsQuery(currentCategory?.id);

  if (!currentCategory) return null;

  return (
    <Block.Content ref={ref}>
      <div className={getStyleClassName("latest-openings")}>
        <p className="texts__component_title--bold">
          {currentCategory.name} – {title}
        </p>
      </div>

      {jobs.map((card, index) => (
        <JobCard key={index} {...card} />
      ))}

      <div className={getStyleClassName("latest-openings", "see-more")}>
        <Link href={generateOpenJobsPageLink(currentCategory)}>
          <Button.Primary text={seeMoreButtonText} />
        </Link>
      </div>
    </Block.Content>
  );
}
