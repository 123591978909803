import { Block } from "@business-finland/wif-ui-lib";
import { SectionHeader } from "@business-finland/wif-ui-lib/dist/SectionHeader";

import { ONE_MIN_IN_MS } from "../../../constants/durations";
import useQuery from "../../../hooks/useQuery";
import { LatestOpenings } from "./LatestOpenings";
import { getStyleClassName, openJobsHighlightId } from "./helpers";
import { PopularCategories } from "./PopularCategories";

export interface IOpenJobsHighlightProps {
  heading: string;
  subHeading: string;
  categoriesTitle?: string;
  openingsTitle?: string;
  seeMoreButtonText?: string;
  totalJobs?: number;
  categories?: Jobs.Status.ICategory[];
}

export function OpenJobsHighlight(props: IOpenJobsHighlightProps): JSX.Element {
  const {
    heading = "Open jobs",
    subHeading = "English speaking jobs across Finland",
    categoriesTitle = "Most popular job categories",
    openingsTitle = "Latest openings",
    seeMoreButtonText = "See more",
    totalJobs = 0,
    categories = [],
  } = props;

  // Revalidating data
  const { data } = useQuery<Jobs.Status.Result>("/api/jobs/status/", {
    cacheDurationInMs: ONE_MIN_IN_MS * 10,
    initialData: { totalJobs, categories },
  });

  return (
    <section
      id={openJobsHighlightId}
      data-testid={openJobsHighlightId}
      className={getStyleClassName()}
    >
      <SectionHeader>{heading}</SectionHeader>
      <Block
        topPadding={Block.Padding.NONE}
        bottomPadding={Block.Padding.SMALL}
      >
        <Block.Content className={getStyleClassName("content")}>
          <div style={{ gridColumn: "1/-1" }}>
            <div className={getStyleClassName("total-count")}>
              {data.totalJobs}
            </div>
            <div className="heading__section">{subHeading}</div>
          </div>
        </Block.Content>

        <PopularCategories
          title={categoriesTitle}
          categories={data.categories}
        />
        <LatestOpenings
          title={openingsTitle}
          categories={data.categories}
          seeMoreButtonText={seeMoreButtonText}
        />
      </Block>
    </section>
  );
}
