import styles from "./OpenJobsHighlight.module.scss";

export const openJobsHighlightId = "open-jobs-highlight";

export function getStyleClassName(...suffixes: string[]) {
  return styles[[openJobsHighlightId, ...suffixes].join("__")];
}

export function generateOpenJobsPageLink(category: Jobs.Status.ICategory) {
  const BASE_URL = "/open-jobs/";
  const searchParams = new URLSearchParams({ category: category.id });

  return BASE_URL + "?" + searchParams.toString();
}
