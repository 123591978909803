import { Block, Chips } from "@business-finland/wif-ui-lib";

import { generateOpenJobsPageLink, getStyleClassName } from "./helpers";

export function PopularCategories({
  title,
  categories = [],
}: {
  title: string;
  categories?: Jobs.Status.ICategory[];
}) {
  if (categories.length === 0) return null;

  return (
    <Block.Content className={getStyleClassName("content")}>
      <div className={getStyleClassName("popular-categories")}>
        <p className="texts__component_title--bold">{title}</p>
        <div className={getStyleClassName("popular-categories", "chips")}>
          {categories.map((category) => (
            <Chips
              key={category.id}
              text={category.name}
              href={generateOpenJobsPageLink(category)}
            />
          ))}
        </div>
      </div>
    </Block.Content>
  );
}
