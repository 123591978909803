import { useMemo } from "react";
import { IJobCardProps } from "@business-finland/wif-ui-lib/dist/JobList";

import { ONE_MIN_IN_MS } from "../../../constants/durations";
import useQuery from "../../../hooks/useQuery";
import useViewport from "../../../contexts/ViewportContext";

const JOBS_COUNT_MOBILE = 3;
const JOBS_COUNT_TABLET_DESKTOP = 6;

export default function useJobsQuery(categoryId?: string): IJobCardProps[] {
  const searchParams = new URLSearchParams({
    offset: "0",
    limit: JOBS_COUNT_TABLET_DESKTOP.toString(),
  });
  if (categoryId) searchParams.set("category", categoryId);

  const { data: result, isLoading } = useQuery<Jobs.All.IJobResult>(
    "/api/jobs/?" + searchParams.toString(),
    {
      disabled: !categoryId,
      cacheDurationInMs: ONE_MIN_IN_MS * 10,
    }
  );

  const jobCardsCount = useJobCardsCount();

  if (isLoading || !result?.jobs)
    return Array(jobCardsCount).fill({ isDataLoading: true });

  return (result?.jobs || []).slice(0, jobCardsCount).map(mapJobToJobCardProps);
}

function useJobCardsCount() {
  const viewport = useViewport();

  return useMemo(
    () =>
      viewport === "xs" || viewport === "sm"
        ? JOBS_COUNT_MOBILE
        : JOBS_COUNT_TABLET_DESKTOP,
    [viewport]
  );
}

function mapJobToJobCardProps(job: Jobs.All.IJobRecordResult): IJobCardProps {
  return {
    title: job.title,
    employerName: job.employer.name,
    location: job.employer.city,
    link: job.externalUrl,
    image: job.employer.imageUrl,
    expireDate: job.expireDate,
    isDataLoading: false,
  };
}
